<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx" v-if="getUsersLoadning">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-card>
        <v-card flat outlined class="mt-7 wraperx" v-else>
          <v-card-title class="mb-0 pb-0 primary--text">
            Users
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              @click="newUserDialog = true"
            >
              <v-icon left> mdi-plus </v-icon> New
            </v-btn>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              filled
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="usersHeaders"
            :items="users"
            :search="search"
            :items-per-page="5"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                depressed
                small
                icon
                color="error"
                class="mx-0 px-0 mt-1"
                dark
                @click="
                  selectedUser = item;
                  deleteUserDialog = true;
                "
              >
                <v-icon small> mdi-trash-can-outline </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- New User dialog -->
    <v-dialog v-model="newUserDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> New User </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newUserForm">
            <v-text-field
              flat
              outlined
              label="Username"
              v-model="newUser.user_name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              flat
              outlined
              label="Email"
              v-model="newUser.email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              flat
              outlined
              label="Display Name"
              v-model="newUser.display_name"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newUserDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="addUserX"
            :loading="newUserLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete user dialog -->
    <v-dialog
      v-model="deleteUserDialog"
      persistent
      max-width="290"
      v-if="selectedUser"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this user? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteUserX(selectedUser.id)"
            :loading="deleteUserLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteUserDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getAllUsers, addUser, deleteUser } from "@/services/usersService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        if (!value) return true;
        return /.+@.+\..+/.test(value) || "Invalid e-mail.";
      },
    },

    usersHeaders: [
      {
        text: "Name",
        align: "start",
        value: "display_name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "User Name",
        value: "user_name",
      },
      { text: "", value: "actions", sortable: false },
    ],
    users: [],
    getUsersLoadning: false,
    search: "",

    newUserDialog: false,
    newUserLoading: false,
    deleteUserDialog: false,
    deleteUserLoading: false,
    selectedUser: null,
    newUser: {
      user_name: "",
      email: "",
      display_name: "",
    },
  }),
  created() {
    this.getUsers();
  },
  mounted() {
    //
  },
  methods: {
    async getUsers() {
      this.getUsersLoadning = true;
      this.users = await getAllUsers();
      this.getUsersLoadning = false;
    },
    async addUserX() {
      if (this.$refs.newUserForm.validate()) {
        this.newUserLoading = true;
        let newUserReturn = await addUser(this.newUser);
        this.newUserLoading = false;

        if (newUserReturn == "success") {
          this.newUserDialog = false;

          this.$refs.newUserForm.reset();
          this.getUsers();
        }
      }
    },
    async deleteUserX(id) {
      this.deleteUserLoading = true;
      let deleteUserReturn = await deleteUser({
        id: id,
      });
      this.deleteUserLoading = false;

      if (deleteUserReturn == "success") {
        this.deleteUserDialog = false;
        this.getUsers();
      }
    },
  },
};
</script>